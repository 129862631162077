import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { rejectProps } from '@frontendmentorio/design-system-v2';
import { selectEntity } from 'lib/helpers';
import {
  fetchLearner,
  fetchLearners,
  fetchLearnerComments,
  fetchCount,
  fetchLearnerSolutions,
} from './thunks';
import {
  deleteCurrentUser,
  fetchCurrentUser,
  fetchCurrentUserBookmarks,
  fetchCurrentUserFollowing,
  fetchCurrentUserFollowers,
  fetchCurrentUserPathProgresses,
  fetchCurrentUserSolutions,
  login,
  updateCurrentUser,
} from 'features/auth/slice/thunks';
import { PRIVATE_PROPS } from 'features/auth/slice';
import { fetchFeed } from 'features/feed/slice';
import { submitStepStage } from 'features/pathProgresses/slice/thunks';
import {
  createSolution,
  deleteSolution,
  fetchSolution,
  fetchSolutionComments,
  updateSolution,
} from 'features/solutions/slice/thunks';
import { fetchWallOfFame } from 'features/wallOfFame/slice';

export const adapter = createEntityAdapter();

const setIdLookup = (state, payload, lookupKey) => {
  payload = [].concat(payload);
  state.idLookup = state.idLookup || {};
  Object.assign(
    state.idLookup,
    Object.fromEntries(
      payload.map((item) => [item[lookupKey].toLowerCase(), item.id])
    )
  );
};

const upsertAssociated = (state, action) => {
  adapter.upsertMany(state, action.payload.associations.users || []);
  setIdLookup(state, action.payload.associations.users || [], 'username');
};

const upsertCurrentUser = (state, action) => {
  adapter.upsertOne(state, rejectProps(action.payload.data, PRIVATE_PROPS));
  setIdLookup(state, action.payload.data, 'username');
  state.currentUserId = action.payload.data.id;
};

const slice = createSlice({
  name: 'learners',
  initialState: adapter.getInitialState({
    count: null,
    currentUserId: null,
    error: null,
    hydrated: false,
    idLookup: {},
    status: 'idle',
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSolution.fulfilled, (state, action) => {
        const user = selectEntity(state, action.payload.data.user);
        user.solutions = [action.payload.data.id, ...(user.solutions || [])];
        user.solutionCount += 1;
      })
      .addCase(deleteCurrentUser.fulfilled, (state, action) => {
        adapter.removeOne(state, action.payload.meta.id);
        state.currentUserId = null;
      })
      .addCase(deleteSolution.fulfilled, (state, action) => {
        const user = state.entities[state.currentUserId];
        user.mentorScore = action.payload.meta.mentorScore;

        if (!user.solutions) return;

        user.solutions = user.solutions.filter(
          (id) => id !== action.payload.meta.id
        );
        user.solutionCount = user.solutions.length;
      })
      .addCase(fetchCurrentUser.fulfilled, upsertCurrentUser)
      .addCase(fetchCurrentUserBookmarks.fulfilled, upsertAssociated)
      .addCase(fetchCurrentUserFollowing.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.data || []);
        setIdLookup(state, action.payload.data, 'username');
      })
      .addCase(fetchCurrentUserFollowers.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.data || []);
        setIdLookup(state, action.payload.data, 'username');
      })
      .addCase(fetchCurrentUserPathProgresses.fulfilled, upsertAssociated)
      .addCase(fetchCurrentUserSolutions.fulfilled, (state, action) => {
        const user = state.entities[state.currentUserId];

        user.solutions = Array.from(
          new Set(action.payload.data.map((solution) => solution.id))
        );
        user.solutionCount = user.solutions.length;
      })
      .addCase(fetchFeed.fulfilled, upsertAssociated)
      .addCase(fetchLearnerComments.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.associations.users || []);
        setIdLookup(state, action.payload.associations.users || [], 'username');

        const user = selectEntity(state, action.meta.arg.id.toLowerCase());

        user.comments = [
          ...(user.comments || []),
          ...action.payload.data.map((comment) => comment.id),
        ];
        user.commentCount = action.payload.page.count;
      })
      .addCase(fetchCount.fulfilled, (state, action) => {
        state.count = action.payload;
      })
      .addCase(fetchSolution.fulfilled, upsertAssociated)
      .addCase(fetchSolutionComments.fulfilled, upsertAssociated)
      .addCase(fetchLearner.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLearner.fulfilled, (state, action) => {
        state.status = 'idle';
        adapter.upsertOne(state, action.payload.data);
        setIdLookup(state, action.payload.data, 'username');
      })
      .addCase(fetchLearner.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchLearnerSolutions.fulfilled, upsertAssociated)
      .addCase(fetchLearners.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.data || []);
        setIdLookup(state, action.payload.data, 'username');
      })

      .addCase(login.fulfilled, upsertCurrentUser)
      .addCase(submitStepStage.fulfilled, (state, action) => {
        upsertAssociated(state, action);

        const [stepId, , stage] = action.meta.arg.stage.split('/');
        const step = action.payload.data.steps.find(
          (step) => step.id === stepId
        );
        const user = selectEntity(state, action.payload.data.user);

        if (stage !== 'submit') return;
        if (user.solutions.includes(step.userSolution)) return;

        user.solutions.unshift(step.userSolution);
        user.solutionCount += 1;
      })
      .addCase(updateCurrentUser.fulfilled, (state, action) => {
        adapter.upsertOne(
          state,
          rejectProps(action.payload.data, PRIVATE_PROPS)
        );
      })
      .addCase(updateSolution.fulfilled, (state, action) => {
        const user = state.entities[state.currentUserId];

        if (!user.solutions) return;
        if (user.solutions.includes(action.payload.data.id)) return;

        user.solutions.unshift(action.payload.data.id);
        user.solutionCount = user.solutions.length;
      })
      .addCase(fetchWallOfFame.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.data || []);
        setIdLookup(state, action.payload.data, 'username');
      })
      .addCase(HYDRATE, (state, action) => {
        if (state.hydrated) return state;

        return {
          ...state,
          ...action.payload.learners,
          hydrated: true,
        };
      });
  },
});

export {
  fetchLearner,
  fetchLearners,
  fetchLearnerComments,
  fetchCount,
  fetchLearnerSolutions,
};

export const {
  selectAll: selectUsers,
  selectById: selectUser,
  selectIds: selectUserIds,
} = adapter.getSelectors((state) => state.learners);

export const selectUsersById = createSelector(
  [(state) => state.learners, (state, ids) => ids],
  (learners, ids = []) =>
    ids.map((id) => learners.entities[id]).filter((user) => !!user)
);

export const selectUserByUsername = (state, username) =>
  selectUser(state, state.learners.idLookup[username.toLowerCase()]);

export const selectNextSolutionPage = (state, id) => {
  const user = state.learners.entities[id];
  if (!user?.solutions) return 1;
  if (user.solutions?.length >= user.solutionCount) return null;

  if ((user.solutions.length / 24) % 1 === 0)
    return user.solutions.length / 24 + 1;

  return null;
};

export const selectCount = (state) =>
  (state.learners.count || 'thousands of').toLocaleString();

export const selectIsLoading = (state) => state.learners.status === 'loading';

export default slice.reducer;
