import { createContext, useEffect } from 'react';
import { getCookie } from 'cookies-next';
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentUser } from 'features/auth/slice';
import {
  appendMessage,
  fetchConversations,
} from 'features/conversations/slice';
import { updateRecruiter } from 'features/recruiters/slice';

import { Socket, useSocket } from '@frontendmentorio/design-system-v2';

const { origin } = new URL(process.env.NEXT_PUBLIC_REST_API_URL);

Socket.init({
  debug: process.env.APP_ENV !== 'production',
  url: origin,
});

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const conversationsSocket = useSocket('/socket/v1/conversations');

  useEffect(() => {
    const handleNewMessage = (data) => {
      try {
        dispatch(appendMessage(data));
      } catch {
        dispatch(fetchConversations());
      }
    };
    const handleUpdateUser = (data) => dispatch(updateRecruiter(data));

    conversationsSocket.on('message:new', handleNewMessage);
    conversationsSocket.on('user:update', handleUpdateUser);

    return () => {
      conversationsSocket.off('message:new', handleNewMessage);
      conversationsSocket.off('user:update', handleUpdateUser);
      conversationsSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!currentUser?.talentProfile?.available) {
      return conversationsSocket.disconnect();
    }

    conversationsSocket.connect({ token: getCookie('fem_token') });
  }, [currentUser, conversationsSocket]);

  return (
    <SocketContext.Provider value={{ conversationsSocket }}>
      {children}
    </SocketContext.Provider>
  );
};
