import NextLink from 'next/link';

import { COMMUNITY_GUIDELINES_URL } from 'features/pages/slice';

import { Heading } from '@frontendmentorio/design-system-v2';

export default function Nav() {
  return (
    <div className="flex flex-col gap-6 mobile:gap-6 mobile:grid mobile:grid-cols-2 tablet:flex tablet:flex-grow tablet:flex-row tablet:justify-between desktop:max-w-[50rem]">
      <div className="flex flex-col gap-4">
        <Heading level="2" tagName="h2">
          Frontend Mentor
        </Heading>

        <ul className="flex flex-col gap-1 text-neutralDarkGrey">
          <li>
            <NextLink className="hover:underline" href="/pro">
              Unlock Pro
            </NextLink>
          </li>

          <li>
            <NextLink className="hover:underline" href="/contact">
              Contact us
            </NextLink>
          </li>

          <li>
            <NextLink className="hover:underline" href="/faq">
              FAQs
            </NextLink>
          </li>

          <li>
            <NextLink className="hover:underline" href="/become-a-partner">
              Become a partner
            </NextLink>
          </li>
        </ul>
      </div>

      <div className="flex flex-col gap-4">
        <Heading level="2" tagName="h2">
          Explore
        </Heading>

        <ul className="flex flex-col gap-1 text-neutralDarkGrey">
          <li>
            <NextLink className="hover:underline" href="/learning-paths">
              Learning paths
            </NextLink>
          </li>

          <li>
            <NextLink className="hover:underline" href="/challenges">
              Challenges
            </NextLink>
          </li>

          <li>
            <NextLink className="hover:underline" href="/solutions">
              Solutions
            </NextLink>
          </li>

          <li>
            <NextLink className="hover:underline" href="/articles">
              Articles
            </NextLink>
          </li>
        </ul>
      </div>

      <div className="flex flex-col gap-4">
        <Heading level="2" tagName="h2">
          Community
        </Heading>

        <ul className="flex flex-col gap-1 text-neutralDarkGrey">
          <li>
            <NextLink className="hover:underline" href="/community">
              Discord
            </NextLink>
          </li>

          <li>
            <NextLink
              className="hover:underline"
              href={COMMUNITY_GUIDELINES_URL}
            >
              Guidelines
            </NextLink>
          </li>
        </ul>
      </div>

      <div className="flex flex-col gap-4">
        <Heading level="2" tagName="h2">
          For companies
        </Heading>

        <ul className="flex flex-col gap-1 text-neutralDarkGrey">
          <li>
            <NextLink
              className="hover:underline"
              href={process.env.HIRING_PLATFORM_URL}
            >
              Hire developers
            </NextLink>
          </li>

          <li>
            <NextLink
              className="hover:underline"
              href={process.env.TEAMS_PLATFORM_URL}
            >
              Train developers
            </NextLink>
          </li>
        </ul>
      </div>
    </div>
  );
}
