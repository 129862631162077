import axios from 'axios';

import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { createAsyncReducer } from '@app/crudReducers';
import { fetchFeed } from 'features/feed/slice';
import { fetchSolutionComments } from 'features/solutions/slice/thunks';
import { fetchLearnerComments } from 'features/learners/slice/thunks';

import {
  deleteCurrentUser,
  fetchCurrentUserPathProgresses,
} from 'features/auth/slice/thunks';
import { submitStepStage } from 'features/pathProgresses/slice/thunks';

export const MIN_CONTENT_LENGTH = 300;

export const upvoteComment = createAsyncReducer(
  'comments/upvote',
  async ({ id, headers }) => {
    return (
      await axios.post(
        `${process.env.REST_API_URL}/v2/comments/${id}/upvote`,
        null,
        { headers }
      )
    ).data;
  }
);

export const adapter = createEntityAdapter();

const slice = createSlice({
  name: 'comments',
  initialState: adapter.getInitialState({
    error: null,
    hydrated: false,
    status: 'idle',
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteCurrentUser.fulfilled, (state, action) => {
        const userComments = Object.values(state.entities).filter(
          (comment) => comment.user !== action.payload.meta.id
        );
        adapter.removeMany(
          state,
          userComments.map((comment) => comment.id)
        );
      })
      .addCase(fetchCurrentUserPathProgresses.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.associations.comments || []);
      })
      .addCase(fetchFeed.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.associations.comments || []);
      })
      .addCase(fetchLearnerComments.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.data);
        adapter.upsertMany(state, action.payload.associations.comments || []);
      })
      .addCase(fetchSolutionComments.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.data);
        adapter.upsertMany(state, action.payload.associations.comments || []);
      })
      .addCase(submitStepStage.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.associations.comments || []);
      })
      .addCase(upvoteComment.fulfilled, (state, action) => {
        adapter.upsertOne(state, action.payload.data);
      })
      .addCase(HYDRATE, (state, action) => {
        if (state.hydrated) return state;

        return {
          ...state,
          ...action.payload.comments,
          hydrated: true,
        };
      });
  },
});

export const { selectById: selectComment } = adapter.getSelectors(
  (state) => state.comments
);

export const selectIsLoading = (state) => state.comments.status === 'loading';

export default slice.reducer;
