import { selectUnreadConversationCount } from 'features/conversations/slice';
import { useSelector } from 'react-redux';

import { Bubble, IconLink } from '@frontendmentorio/design-system-v2';

export default function NavLink() {
  const unreadCount = useSelector(selectUnreadConversationCount);

  return (
    <IconLink
      ariaLabel={`Messaging: you have ${unreadCount} unread conversations`}
      href="/messaging"
      icon="comment"
      notch={unreadCount > 0 && <Bubble>{unreadCount}</Bubble>}
    />
  );
}
