import useNotifications from 'lib/hooks/useNotifications';

import { Bubble, IconLink } from '@frontendmentorio/design-system-v2';

export default function NavLink() {
  const { pendingIds } = useNotifications();

  return (
    <IconLink
      ariaLabel={`Notifications: you have ${pendingIds.length} unread notifications`}
      href="/notifications"
      icon="bell"
      notch={
        pendingIds.length > 0 && (
          <Bubble className="bg-primaryRed">{pendingIds.length}</Bubble>
        )
      }
    />
  );
}
