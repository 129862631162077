import {
  createAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { setIdLookup } from 'lib/helpers';

import {
  fetchCurrentUserPathProgresses,
  startLearningPath,
} from 'features/auth/slice/thunks';
import { selectLearningPathBySlug } from 'features/learningPaths/slice';

import { submitStepStage } from './thunks';

const signOut = createAction('auth/signOut');

export const adapter = createEntityAdapter();

const slice = createSlice({
  name: 'pathProgresses',
  initialState: adapter.getInitialState({
    error: null,
    hydrated: false,
    idLookup: {},
    status: 'idle',
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUserPathProgresses.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.data);
        setIdLookup(state, action.payload.data || [], 'path');
      })
      .addCase(signOut, (state) => {
        adapter.removeAll(state);
      })
      .addCase(startLearningPath.fulfilled, (state, action) => {
        adapter.upsertOne(state, action.payload.data);
        setIdLookup(state, action.payload.data || [], 'path');
      })
      .addCase(submitStepStage.fulfilled, (state, action) => {
        adapter.upsertOne(state, action.payload.data);
        state.status = 'idle';
      })
      .addCase(submitStepStage.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(submitStepStage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(HYDRATE, (state, action) => {
        if (state.hydrated) return state;

        return {
          ...state,
          ...action.payload.pathProgresses,
          hydrated: true,
        };
      });
  },
});

export const {
  selectAll: selectPathProgresses,
  selectById: selectPathProgress,
} = adapter.getSelectors((state) => state.pathProgresses);

export const selectPathProgressByPathId = (state, id) => {
  return selectPathProgress(state, state.pathProgresses.idLookup[id]);
};
export const selectPathProgressByPathSlug = (state, slug) => {
  const path = selectLearningPathBySlug(state, slug);
  return selectPathProgress(state, state.pathProgresses.idLookup[path?.id]);
};

export const selectStep = (state, id, stepId) => {
  const progress = selectPathProgress(state, id);
  return progress?.steps.find((step) => step.id === stepId);
};

export const selectNextPath = (state, id) => {
  const progress = selectPathProgress(state, id);
  const idx = state.learningPaths.ids.indexOf(progress.path);

  if (idx < 0) return null;

  return state.learningPaths.entities[state.learningPaths.ids[idx + 1]];
};

export const selectNextStep = (state, id, stepId) => {
  const progress = selectPathProgress(state, id);
  const idx = progress.steps.findIndex((step) => step.id === stepId);
  return progress.steps[idx + 1];
};

export const selectIsLoading = (state) =>
  state.pathProgresses.status === 'loading';

export default slice.reducer;
