import axios from 'axios';
import { trackEvent } from 'fathom-client';

import {
  Button,
  FieldWrapper,
  flash,
  Form,
  Heading,
  InputWrapper,
  Label,
  TextInput,
} from '@frontendmentorio/design-system-v2';

export default function Newsletter() {
  const handleSubmit = async (data, state, methods) => {
    try {
      const response = await axios.post(
        `${process.env.REST_API_URL}/v2/mailing-lists/newsletter`,
        data
      );

      flash.success(response.data.message);

      trackEvent('Newsletter Sign Up');

      methods.reset();
    } catch (error) {
      flash.error(
        error.response?.data?.message ||
          'An error has occurred, please try again'
      );
    }
  };

  return (
    <div className="flex flex-col gap-4 max-w-[28rem] w-full">
      <p className="flex flex-col gap-2">
        <Heading
          className="text-primaryBlue leading-normal"
          level="5"
          tagName="span"
        >
          Stay up to date
        </Heading>

        <span>
          with new challenges, featured solutions, selected articles, and our
          latest news
        </span>
      </p>

      <Form
        className="flex flex-col gap-4 mobile:flex-row mobile:items-start"
        onSubmit={handleSubmit}
      >
        <FieldWrapper className="w-full">
          <Label className="sr-only">Email</Label>

          <InputWrapper
            input={TextInput}
            name="email"
            placeholder="email@example.com"
            type="email"
            validations={{ email: true, required: true }}
          />
        </FieldWrapper>

        <Button>Subscribe</Button>
      </Form>
    </div>
  );
}
