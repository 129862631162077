import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { createCrudReducers } from '@app/crudReducers';

const adapter = createEntityAdapter({
  sortComparer: (a, b) => b.slug.localeCompare(a.slug),
});

export const { fetchAll: fetchCategories } = createCrudReducers(
  'categories',
  `${process.env.REST_API_URL}/v2/categories`
);

const slice = createSlice({
  name: 'categories',
  initialState: adapter.getInitialState({
    hydrated: false,
    status: 'idle',
    error: null,
  }),
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = null;
        adapter.upsertMany(state, action.payload.data);
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(HYDRATE, (state, action) => {
        if (state.hydrated) return state;

        return {
          ...state,
          ...action.payload.categories,
          hydrated: true,
        };
      });
  },
});

export const selectCategoriesById = createSelector(
  [(state) => state.categories, (state, ids) => ids || []],
  (categories, ids) =>
    ids.map((id) => categories.entities[id]).filter((category) => !!category)
);

export const selectIsLoading = (state) => state.categories.status === 'loading';

export default slice.reducer;
