import { selectCurrentUser } from 'features/auth/slice';
import { useSelector } from 'react-redux';

import { NavLink } from '@frontendmentorio/design-system-v2';

import GitHubAuthButton from 'features/shared/GitHubAuthButton';

export default function NavLinks() {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <nav aria-label="Primary" className="hidden desktop:block">
      <ul className="flex gap-4 items-center">
        <li>
          <NavLink href="/learning-paths">Learning paths</NavLink>
        </li>

        <li>
          <NavLink href="/challenges">Challenges</NavLink>
        </li>

        <li>
          <NavLink href="/solutions">Solutions</NavLink>
        </li>

        <li>
          <NavLink href="/articles">Articles</NavLink>
        </li>

        {!currentUser?.hasActiveSubscription && (
          <li>
            <NavLink href="/pro">Unlock Pro</NavLink>
          </li>
        )}

        {!currentUser && (
          <li>
            <GitHubAuthButton className="py-2 text-sm" />
          </li>
        )}
      </ul>
    </nav>
  );
}
