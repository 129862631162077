import {
  createAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import {
  createMessage,
  fetchConversations,
  updateConversation,
} from 'features/conversations/slice';

const signOut = createAction('auth/signOut');

const adapter = createEntityAdapter();

const slice = createSlice({
  name: 'recruiters',
  initialState: adapter.getInitialState({}),
  reducers: {
    updateRecruiter: (state, action) => {
      adapter.upsertOne(state, action.payload.data);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConversations.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.associations.users || []);
      })
      .addCase(createMessage.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.associations.users || []);
      })
      .addCase(updateConversation, (state, action) => {
        adapter.upsertMany(state, action.payload.associations.users || []);
      })
      .addCase(signOut, (state) => {
        adapter.removeAll(state);
      })
      .addCase(HYDRATE, (state, action) => {
        return {
          ...state,
          ...action.payload.recruiters,
        };
      });
  },
});

export const { selectById: selectRecruiter } = adapter.getSelectors(
  (state) => state.recruiters
);

export const { updateRecruiter } = slice.actions;

export default slice.reducer;
