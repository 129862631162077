import axios from 'axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getRequestHeaders } from 'lib/helpers';
import { flash } from '@frontendmentorio/design-system-v2';

const signOut = createAction('auth/signOut');

export const createAsyncReducer = (
  name,
  requestMethod,
  { condition, errorMessage, onSuccess, successMessage } = {}
) => {
  return createAsyncThunk(
    name,
    async ({ ctx, ...options } = {}, { dispatch, rejectWithValue }) => {
      try {
        const headers = getRequestHeaders(ctx);
        const response = await requestMethod({
          ...options,
          headers,
        });

        onSuccess && onSuccess(response, dispatch);
        successMessage && flash.success(successMessage);
        return response;
      } catch (error) {
        const message = error.response?.data.message || error.message;
        const code = error.response?.status || error.code;

        if (code === 401) {
          dispatch(signOut());
          flash.error('Your session has expired please log in again.');
        }

        errorMessage !== false && flash.error(errorMessage || message);

        return rejectWithValue({
          code,
          message,
          name: error.name,
          stack: error.stack,
        });
      }
    },
    {
      condition,
    }
  );
};

export const createCrudReducers = (name, endpoint, options = {}) => ({
  fetchAll: createAsyncReducer(
    `${name}/fetchAll`,
    async ({ params = {}, headers }) => {
      return (await axios.get(endpoint, { params, headers })).data;
    },
    {
      condition: ({ params = {} } = {}, { getState }) => {
        const state = getState()[name];

        if (state.status === 'loading') return false;

        if (state.page && state.page.current !== null) {
          if ((params.page || 1) <= state.page.current) return false;
        }
      },
      ...(options.fetchAll || {}),
    }
  ),
  fetchById: createAsyncReducer(
    `${name}/fetchById`,
    async ({ id, headers }) => {
      return (await axios.get(`${endpoint}/${id}`, { headers })).data;
    },
    {
      condition: ({ id }, { getState }) => {
        const state = getState()[name];

        if (state.error) return false;
        if (state.status === 'loading') return false;
        if (state.ids.includes(id)) return false;
        if (state.idLookup && !!state.idLookup[id.toLowerCase()]) return false;
      },
      ...(options.fetchById || {}),
    }
  ),
  create: createAsyncReducer(
    `${name}/create`,
    async ({ data, headers }) => {
      return (await axios.post(endpoint, data, { headers })).data;
    },
    {
      ...(options.create || {}),
    }
  ),
  update: createAsyncReducer(
    `${name}/update`,
    async ({ data, id, headers }) => {
      return (await axios.put(`${endpoint}/${id}`, data, { headers })).data;
    },
    {
      ...(options.update || {}),
    }
  ),
  delete: createAsyncReducer(
    `${name}/delete`,
    async ({ id, headers }) => {
      return (await axios.delete(`${endpoint}/${id}`, { headers })).data;
    },
    {
      ...(options.delete || {}),
    }
  ),
});
