import axios from 'axios';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { createAsyncReducer } from '@app/crudReducers';
import { HYDRATE } from 'next-redux-wrapper';

export const adapter = createEntityAdapter({
  sortComparer: (a, b) => b.price - a.price,
});

export const fetchPlans = createAsyncReducer(
  'plans/fetchAll',
  async ({ currency, headers }) => {
    return (
      await axios.get(`${process.env.REST_API_URL}/v2/plans`, {
        headers,
        params: { currency, 'filter[family]': 'learning' },
      })
    ).data;
  },
  {
    condition: (_, { getState }) => {
      const state = getState().plans;
      if (state.status === 'loading') return false;

      if (state.ids.length) return false;

      return true;
    },
  }
);

const slice = createSlice({
  name: 'plans',
  initialState: adapter.getInitialState({
    error: null,
    hydrated: false,
    status: 'idle',
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.status = 'idle';
        adapter.upsertMany(
          state,
          action.payload.data.map((item) => item.options[0])
        );
      })
      .addCase(fetchPlans.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchPlans.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(HYDRATE, (state, action) => {
        if (state.hydrated) return state;

        return {
          ...state,
          ...action.payload.plans,
          hydrated: true,
        };
      });
  },
});

export const { selectAll: selectPlans } = adapter.getSelectors(
  (state) => state.plans
);

export const selectIsLoading = (state) => state.plans.status === 'loading';

export default slice.reducer;
