import axios from 'axios';
import { createAsyncReducer } from '@app/crudReducers';

export const submitStepStage = createAsyncReducer(
  'pathProgresses/submitStepStage',
  async ({ data, headers, id, stage }) => {
    return (
      await axios.post(
        `${process.env.REST_API_URL}/v2/path-progresses/${id}/steps/${stage}`,
        data,
        { headers }
      )
    ).data;
  }
);
