import { forwardRef } from 'react';

import { Icon, Menu, NavLink } from '@frontendmentorio/design-system-v2';

import GitHubAuthButton from 'features/shared/GitHubAuthButton';

const MenuButton = forwardRef(({ isActive, ...props }, ref) => {
  return (
    <button {...props} aria-label="Toggle primary navigation" ref={ref}>
      <Menu.Burger isActive={isActive} />
    </button>
  );
});

export default function SignedOutMenu() {
  return (
    <div className="desktop:hidden">
      <Menu button={MenuButton}>
        <Menu.Nav ariaLabel="Primary">
          <Menu.Item>
            <NavLink
              className="gap-4"
              href="/learning-paths"
              isUnderlineOnHover={false}
            >
              <Icon className="text-lg" name="arrow-trend-up" /> Learning paths
            </NavLink>
          </Menu.Item>

          <Menu.Item>
            <NavLink
              className="gap-4"
              href="/challenges"
              isUnderlineOnHover={false}
            >
              <Icon className="text-lg" name="laptop-mobile" /> Challenges
            </NavLink>
          </Menu.Item>

          <Menu.Item>
            <NavLink
              className="gap-4"
              href="/solutions"
              isUnderlineOnHover={false}
            >
              <Icon className="text-lg" name="code" /> Solutions
            </NavLink>
          </Menu.Item>

          <Menu.Item>
            <NavLink
              className="gap-4"
              href="/articles"
              isUnderlineOnHover={false}
            >
              <Icon className="text-lg" name="newspaper" />
              Articles
            </NavLink>
          </Menu.Item>

          <Menu.Item>
            <NavLink className="gap-4" href="/pro" isUnderlineOnHover={false}>
              <Icon className="text-lg" name="unlock" /> Unlock Pro
            </NavLink>
          </Menu.Item>

          <li className="p-5 tablet:py-4">
            <GitHubAuthButton className="w-full" />
          </li>
        </Menu.Nav>
      </Menu>
    </div>
  );
}
