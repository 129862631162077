import { GITHUB_OAUTH_URL } from 'features/pages/slice';
import { setRedirectPath } from 'lib/helpers';
import { tv } from 'tailwind-variants';

import { Button, Icon } from '@frontendmentorio/design-system-v2';

const styles = tv({
  extend: Button.styles,
  base: ['gap-2', 'whitespace-nowrap'],
  variants: {
    level: {
      primary:
        'bg-neutralBlack border-neutralBlack hover:bg-neutralBlack-lighter hover:border-neutralBlack-lighter text-neutralWhite',
      secondary:
        'bg-primaryRed border-primaryRed hover:bg-primaryRed-darker hover:border-primaryRed-darker text-neutralWhite',
      tertiary:
        'bg-transparent border-current hover:bg-transparent text-primaryRed',
    },
  },
});

export default function GitHubAuthButton({
  className,
  isWide,
  level = 'primary',
  redirect = true,
}) {
  return (
    <a
      className={styles({ class: className, isWide, level })}
      href={GITHUB_OAUTH_URL}
      onClick={redirect ? setRedirectPath : undefined}
    >
      Log in with GitHub
      <Icon className="text-lg" name="github" style="brands" />
    </a>
  );
}
