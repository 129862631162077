import NextLink from 'next/link';
import { useSelector } from 'react-redux';
import useDiscountTimer from 'lib/hooks/useDiscountTimer';

import { selectCurrentUser } from 'features/auth/slice';

export default function DiscountBanner() {
  const currentUser = useSelector(selectCurrentUser);
  const { timeRemaining } = useDiscountTimer();

  if (!currentUser?.discount) return null;

  return (
    <div className="bg-primaryBlue font-semibold text-center text-neutralWhite px-5 py-2">
      You've unlocked a Pro discount, which expires in {timeRemaining}.{' '}
      <NextLink className="underline hover:no-underline" href="/pro#plans">
        Upgrade now
      </NextLink>
    </div>
  );
}
