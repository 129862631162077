import NextLink from 'next/link';
import { tv } from 'tailwind-variants';

const styles = tv({
  slots: {
    wrapper: ['relative'],
    notch: [
      'absolute',
      'h-[1px]',
      'right-[13%]',
      'top-[13%]',
      'w-[1px]',
      '*:absolute',
      '*:left-1/2',
      '*:top-1/2',
      '*:-translate-x-1/2',
      '*:-translate-y-1/2',
    ],
  },
  variants: {
    size: {
      xs: {
        wrapper: ['w-[2rem]'],
        notch: ['*:min-h-[1rem]', '*:text-[0.7rem]', '*:w-[1rem]'],
      },
      sm: {
        wrapper: ['w-[2.5rem]'],
        notch: ['*:min-h-[1.2rem]', '*:text-[0.8rem]', '*:w-[1.2rem]'],
      },
      md: {
        wrapper: ['w-[3.125rem]'],
        notch: ['*:min-h-[1.25rem]', '*:text-[0.8rem]', '*:w-[1.25rem]'],
      },
      lg: {
        wrapper: ['w-[5rem]'],
        notch: ['*:min-h-[1.5rem]', '*:text-[1rem]', '*:w-[1.5rem]'],
      },
      xl: {
        wrapper: ['w-[10rem]'],
        notch: ['*:min-h-[3rem]', '*:text-[1.8rem]', '*:w-[3rem]'],
      },
      profile: {
        wrapper: ['max-w-[15rem]', 'w-full'],
        notch: ['*:min-h-[2rem]', '*:text-lg', '*:w-[2rem]'],
      },
    },
  },
});

import { Avatar } from '@frontendmentorio/design-system-v2';

import Notch from './Notch';

export default function AppAvatar({
  className,
  href,
  size = 'md',
  user,
  withModal,
  ...props
}) {
  const { notch, wrapper } = styles({ size });

  return (
    <div className={wrapper({ class: className })}>
      {href ? (
        <NextLink {...props} href={href} aria-hidden="true" tabIndex="-1">
          <Avatar image={user.avatar} size={size} />
        </NextLink>
      ) : (
        <Avatar {...props} image={user.avatar} size={size} />
      )}
      <div className={notch({ size })}>
        <Notch user={user} withModal={withModal} />
      </div>
    </div>
  );
}
