import { tv } from 'tailwind-variants';

import { selectCurrentUser } from 'features/auth/slice';
import { useSelector } from 'react-redux';

import { Bubble, Icon, IconLink } from '@frontendmentorio/design-system-v2';

const notchStyles = tv({
  base: ['bg-neutralSilverGrey', 'text-[0.8rem]'],
  variants: {
    availableForWork: {
      true: ['bg-accentGreen'],
    },
  },
});

export default function NavLink() {
  const currentUser = useSelector(selectCurrentUser);
  const availableForWork = currentUser?.talentProfile?.available;

  return (
    <IconLink
      ariaLabel={`Set your talent profile. You are currently ${
        availableForWork ? 'available' : 'unavailable'
      } for work.`}
      href="/talent-profile"
      icon="briefcase"
      notch={
        <div>
          <Bubble className={notchStyles({ availableForWork })} size="xs">
            <Icon
              className="text-neutralWhite text-xs"
              name={availableForWork ? 'check' : 'xmark'}
              style="solid"
            />
          </Bubble>
        </div>
      }
    />
  );
}
