import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { fetchArticle, fetchArticles } from 'features/articles/slice';

const adapter = createEntityAdapter({
  sortComparer: (a, b) => b.name.localeCompare(a.name),
});

const slice = createSlice({
  name: 'authors',
  initialState: adapter.getInitialState({
    hydrated: false,
    status: 'idle',
    error: null,
  }),
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticle.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.associations.users || []);
      })
      .addCase(fetchArticles.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.associations.users || []);
      })
      .addCase(HYDRATE, (state, action) => {
        if (state.hydrated) return state;

        return {
          ...state,
          ...action.payload.authors,
          hydrated: true,
        };
      });
  },
});

export const { selectById: selectAuthor } = adapter.getSelectors(
  (state) => state.authors
);

export default slice.reducer;
