import axios from 'axios';
import { HYDRATE } from 'next-redux-wrapper';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { createAsyncReducer } from '@app/crudReducers';

export const fetchWallOfFame = createAsyncReducer(
  'wallOfFame/fetchAll',
  async ({ params, headers }) => {
    return (
      await axios.get(`${process.env.REST_API_URL}/v2/wall-of-fame`, {
        params,
        headers,
      })
    ).data;
  },
  {
    condition: ({ params } = {}, { getState }) => {
      const state = getState().wallOfFame;

      if (!params?.period) {
        return false;
      }

      if (state[params.period].length) {
        return false;
      }

      return true;
    },
  }
);

const slice = createSlice({
  name: 'wallOfFame',
  initialState: {
    hydrated: false,
    month: [],
    total: [],
    week: [],
    year: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWallOfFame.fulfilled, (state, action) => {
        state[action.meta.arg.params.period] = action.payload.data.map(
          (user) => user.id
        );
      })
      .addCase(HYDRATE, (state, action) => {
        if (state.hydrated) return state;

        return {
          ...state,
          ...action.payload.wallOfFame,
          hydrated: true,
        };
      });
  },
});

export const selectWallOfFameUsers = createSelector(
  [
    (state) => state.wallOfFame,
    (state) => state.learners,
    (state, period) => period || 'total',
  ],
  (wallOfFame, learners, period) =>
    wallOfFame[period]
      .map((id) => learners.entities[id])
      .filter((user) => !!user)
);

export default slice.reducer;
