import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { createCrudReducers } from '@app/crudReducers';

export const adapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
});

export const { fetchAll: fetchFeed } = createCrudReducers(
  'feed',
  `${process.env.REST_API_URL}/v2/feed`
);

const slice = createSlice({
  name: 'feed',
  initialState: adapter.getInitialState({
    error: null,
    hydrated: false,
    page: { current: 0, next: null, prev: null, total: 0 },
    status: 'idle',
  }),
  reducers: {
    reset(state) {
      adapter.removeAll(state);
      state.page = { current: 0, next: null, prev: null, total: 0 };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeed.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFeed.fulfilled, (state, action) => {
        state.status = 'idle';
        adapter.upsertMany(state, action.payload.data);
        state.page = action.payload.page;
      })
      .addCase(fetchFeed.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(HYDRATE, (state, action) => {
        if (state.hydrated) return state;

        return {
          ...state,
          ...action.payload.feed,
          hydrated: true,
        };
      });
  },
});

export const { selectAll: selectFeed, selectIds: selectFeedIds } =
  adapter.getSelectors((state) => state.feed);

export const { reset: resetFeed } = slice.actions;

export const selectCurrentPage = (state) => state.feed.page.current;
export const selectNextPage = (state) => state.feed.page.next;

export const selectIsLoading = (state) => state.feed.status === 'loading';

export default slice.reducer;
