import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';

import { removeDiscount, selectCurrentUser } from 'features/auth/slice';

import { pluralize } from '@frontendmentorio/design-system-v2';

const padNum = (num) => num.toString().padStart(2, '0');

export default function useDiscountTimer() {
  const dispatch = useDispatch();
  const timer = useRef();

  const currentUser = useSelector(selectCurrentUser);
  const [currentDate, setCurrentDate] = useState(new Date());
  const endDate = new Date(currentUser?.discount?.expiresAt);

  useEffect(() => {
    if (!currentUser?.discount) return clearInterval(timer.current);

    timer.current = setInterval(() => setCurrentDate(new Date()), 1000);

    return () => clearInterval(timer.current);
  }, [currentUser?.discount]);

  useEffect(() => {
    if (!currentUser?.discount) return;
    if (currentDate <= new Date(currentUser.discount.expiresAt)) return;

    clearInterval(timer.current);
    dispatch(removeDiscount());
  }, [currentDate, currentUser]);

  if (!currentUser?.discount) return {};

  const days = padNum(differenceInDays(endDate, currentDate));
  const hours = padNum(differenceInHours(endDate, currentDate) % 24);
  const mins = padNum(differenceInMinutes(endDate, currentDate) % 60);
  const secs = padNum(differenceInSeconds(endDate, currentDate) % 60);

  const timeRemaining = +days
    ? `${pluralize(+days, 'day')}`
    : `${hours} hours, ${mins} mins, ${secs} secs`;

  return { days, hours, mins, secs, timeRemaining };
}
