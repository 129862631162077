import { useEffect, useState } from 'react';
import Head from 'next/head';

import { selectCurrentUser, selectIsLoading } from 'features/auth/slice';
import { useSelector } from 'react-redux';

import {
  FlashMessage,
  LoadingScreen,
  LockScreen,
} from '@frontendmentorio/design-system-v2';

import Footer from 'features/shared/Footer';
import GitHubAuthButton from 'features/shared/GitHubAuthButton';
import Header from 'features/shared/Header';

export default function Layout({
  children,
  description,
  image,
  isAuth,
  isIndexed = true,
  title = 'Frontend Mentor | Front-end coding challenges using a real-life workflow',
  url,
}) {
  const [isRendered, setIsRendered] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const isLoading = useSelector(selectIsLoading);

  const renderContent = () => {
    if (isAuth) {
      if (isLoading || !isRendered) return <LoadingScreen />;

      if (!currentUser) {
        return (
          <LockScreen>
            <LockScreen.Heading>Oops! You're not logged in</LockScreen.Heading>

            <p>Please log in to view this page.</p>

            <GitHubAuthButton />
          </LockScreen>
        );
      }
    }

    return children;
  };

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <>
      <Head>
        <meta name="description" content={description} />

        <meta property="fb:app_id" content="401466220320820" />

        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:image:height" content="512" />
        <meta property="og:image:width" content="768" />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:site_name" content="Frontend Mentor" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />

        {!isIndexed && <meta name="robots" content="noindex" />}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:site" content="@frontendmentor" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:url" content={url} />

        <title>{title}</title>
      </Head>

      {isRendered && <FlashMessage />}

      <a className="sr-only" href="#content">
        Skip to content
      </a>

      <div className="flex flex-col min-h-screen">
        <Header />

        <main className="flex-grow" id="content">
          {renderContent()}
        </main>

        <Footer />
      </div>
    </>
  );
}
