import { useSWRConfig } from 'swr';
import useSWRInfinite from 'swr/infinite';
import { fetcher } from './useFetch';

export default function useFetchInfinite(
  key,
  { fallbackData, ...options } = {}
) {
  const { fallback } = useSWRConfig();
  const initialData = fallbackData ? [fallbackData] : fallback[key];

  const { data, setSize, size, ...rest } = useSWRInfinite(
    (pageIdx, prevPage) => {
      const [path, query] = key.split('?');
      const qs = new URLSearchParams(query);

      if (prevPage && !prevPage.meta.page.next) return null;
      if (pageIdx === 0) return key;

      qs.set('page', prevPage.meta.page.next);

      return `${path}?${qs}`;
    },
    fetcher,
    {
      errorRetryCount: 0,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateFirstPage: false,
      fallbackData: initialData,
      ...options,
    }
  );

  const flattened = data
    ? {
        data: data.reduce((memo, page) => memo.concat(page.data), []),
        meta: data[data.length - 1].meta,
      }
    : {};

  return { data: flattened, setPage: setSize, ...rest };
}
