import useSWR from 'swr';

export const fetcher = async (...args) => {
  const res = await fetch(...args);
  const data = await res.json();

  if (!res.ok) throw new Error(data.message);
  return data;
};

export default function useFetch(key, options) {
  const { data = {}, ...rest } = useSWR(key, fetcher, {
    errorRetryCount: 0,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    ...options,
  });

  return { data, ...rest };
}
