import axios from 'axios';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { createAsyncReducer } from '@app/crudReducers';
import { setIdLookup } from 'lib/helpers';

import {
  fetchLearningPaths,
  fetchLearningPath,
} from 'features/learningPaths/slice';

const adapter = createEntityAdapter({
  sortComparer: (a, b) => b.publishedAt.localeCompare(a.publishedAt),
});

export const fetchArticles = createAsyncReducer(
  'articles/fetchAll',
  async ({ headers, params }) => {
    return (
      await axios.get(`${process.env.REST_API_URL}/v2/articles`, {
        headers,
        params: { ...params, 'filter[platform]': 'learning' },
      })
    ).data;
  },
  {
    condition: ({ params = {} } = {}, { getState }) => {
      const state = getState().articles;

      if (state.status === 'loading') return false;

      if (state.page && state.page.current !== null) {
        if ((params.page || 1) <= state.page.current) return false;
      }
    },
  }
);

export const fetchArticle = createAsyncReducer(
  'articles/fetchById',
  async ({ headers, id }) => {
    return (
      await axios.get(`${process.env.REST_API_URL}/v2/articles/${id}`, {
        headers,
      })
    ).data;
  }
);

const slice = createSlice({
  name: 'articles',
  initialState: adapter.getInitialState({
    error: null,
    hydrated: false,
    idLookup: {},
    page: { current: 0, next: null, prev: null, total: 0 },
    status: 'idle',
  }),
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticle.fulfilled, (state, action) => {
        adapter.upsertOne(state, action.payload.data);
        setIdLookup(state, action.payload.data || [], 'slug');
      })
      .addCase(fetchArticles.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.data);
        setIdLookup(state, action.payload.data || [], 'slug');
        state.page = action.payload.page;
        state.status = 'idle';
        state.error = null;
      })
      .addCase(fetchArticles.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchArticles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchLearningPath.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.associations?.articles || []);
        setIdLookup(state, action.payload.associations?.articles || [], 'slug');
      })
      .addCase(fetchLearningPaths.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.associations?.articles || []);
        setIdLookup(state, action.payload.associations?.articles || [], 'slug');
      })
      .addCase(HYDRATE, (state, action) => {
        if (state.hydrated) return state;

        return {
          ...state,
          ...action.payload.articles,
          hydrated: true,
        };
      });
  },
});

export const { selectById: selectArticle } = adapter.getSelectors(
  (state) => state.articles
);

export const selectIsLoading = (state) => state.articles.status === 'loading';

export const selectNextPage = (state) => state.articles.page.next;

export default slice.reducer;
