import axios from 'axios';
import { findLast } from 'lodash';
import { createAsyncReducer } from '@app/crudReducers';
import {
  createAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { setIdLookup } from 'lib/helpers';

import { recordsMatch } from '@frontendmentorio/design-system-v2';

const signOut = createAction('auth/signOut');

export const adapter = createEntityAdapter({
  sortComparer: (a, b) => b.updatedAt.localeCompare(a.updatedAt),
});

export const createMessage = createAsyncReducer(
  'conversations/create',
  async ({ data, headers }) => {
    return (
      await axios.post(`${process.env.REST_API_URL}/v2/conversations`, data, {
        headers,
      })
    ).data;
  }
);

export const fetchConversations = createAsyncReducer(
  'conversations/fetchAll',
  async ({ headers }) => {
    return (
      await axios.get(`${process.env.REST_API_URL}/v2/conversations`, {
        headers,
      })
    ).data;
  }
);

export const markAsRead = createAsyncReducer(
  'conversations/markAsRead',
  async ({ headers, id }) => {
    return (
      await axios.post(
        `${process.env.REST_API_URL}/v2/conversations/${id}/mark-as-read`,
        null,
        { headers }
      )
    ).data;
  }
);

const slice = createSlice({
  name: 'conversations',
  initialState: adapter.getInitialState({
    status: 'idle',
    error: null,
    idLookup: {},
  }),
  reducers: {
    updateConversation: (state, action) => {
      adapter.upsertOne(state, action.payload.data);
    },
    appendMessage: (state, action) => {
      const conversation =
        state.entities[state.idLookup[action.payload.data.user]];

      if (!conversation) throw new Error('Unknown conversation');

      adapter.upsertOne(state, {
        ...conversation,
        messages: [...conversation.messages, action.payload.data],
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConversations.fulfilled, (state, action) => {
        state.status = 'idle';
        adapter.upsertMany(state, action.payload.data);
        setIdLookup(state, action.payload.data || [], 'with');
      })
      .addCase(createMessage.fulfilled, (state, action) => {
        state.status = 'idle';
        adapter.upsertOne(state, action.payload.data);
        setIdLookup(state, action.payload.data, 'with');
      })
      .addCase(markAsRead.fulfilled, (state, action) => {
        state.status = 'idle';
        adapter.upsertOne(state, action.payload.data);
      })
      .addCase(signOut, (state) => {
        adapter.removeAll(state);
      })
      .addCase(HYDRATE, (state, action) => {
        return {
          ...state,
          ...action.payload.conversations,
        };
      });
  },
});

export const {
  selectAll: selectConversations,
  selectById: selectConversation,
} = adapter.getSelectors((state) => state.conversations);

export const selectUnreadConversationCount = (state) => {
  const conversations = selectConversations(state);

  return conversations.reduce((memo, conversation) => {
    const message = findLast(
      conversation.messages,
      (message) => !recordsMatch(message.user, state.auth.currentUser)
    );

    if (!message) return memo;

    return memo + !message?.isRead;
  }, 0);
};

export const selectLastResponseByConversationId = (state, conversationId) => {
  return findLast(
    state.conversations.entities[conversationId]?.messages,
    (message) => !recordsMatch(message.user, state.auth.currentUser)
  );
};

export const { appendMessage, updateConversation } = slice.actions;

export default slice.reducer;
