import axios from 'axios';
import { recordsContain } from '@frontendmentorio/design-system-v2';
import { createAsyncReducer, createCrudReducers } from '@app/crudReducers';

import { resetFeed } from 'features/feed/slice';

export const { fetchAll: fetchChallenges, fetchById: fetchChallenge } =
  createCrudReducers(
    'challenges',
    `${process.env.REST_API_URL}/v2/challenges`,
    {
      fetchAll: {
        condition: (_, { getState }) => {
          const state = getState().challenges;

          return state.ids.length < 100;
        },
      },
    }
  );

export const downloadDesign = createAsyncReducer(
  'challenges/design',
  async ({ id, headers }) => {
    return (
      await axios.get(
        `${process.env.REST_API_URL}/v2/challenges/${id}/design`,
        {
          params: { type: 'figma' },
          headers,
        }
      )
    ).data;
  },
  {
    onSuccess: async (response) => {
      window.location.assign(response.meta.downloadUrl);
    },
  }
);

export const downloadStarter = createAsyncReducer(
  'challenges/starter',
  async ({ id, headers }) => {
    return (
      await axios.get(
        `${process.env.REST_API_URL}/v2/challenges/${id}/starter`,
        { headers }
      )
    ).data;
  },
  {
    onSuccess: (response) => {
      window.location.assign(response.meta.downloadUrl);
    },
  }
);

export const lockChallenge = createAsyncReducer(
  'challenges/lock',
  async ({ id, headers }) => {
    return (
      await axios.post(
        `${process.env.REST_API_URL}/v2/challenges/${id}/lock`,
        {},
        { headers }
      )
    ).data;
  },
  {
    condition: ({ id } = {}, { getState }) => {
      const currentUser = getState().auth.currentUser;

      if (!recordsContain(currentUser.unlockedHubs, id)) return false;
    },
    onSuccess: async (response, dispatch) => {
      dispatch(resetFeed());
    },
  }
);

export const unlockChallenge = createAsyncReducer(
  'challenges/unlock',
  async ({ id, headers }) => {
    return (
      await axios.post(
        `${process.env.REST_API_URL}/v2/challenges/${id}/unlock`,
        {},
        { headers }
      )
    ).data;
  },
  {
    condition: ({ id } = {}, { getState }) => {
      const currentUser = getState().auth.currentUser;

      if (recordsContain(currentUser.unlockedHubs, id)) return false;
    },
  }
);
