import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { createCrudReducers } from '@app/crudReducers';
import { HYDRATE } from 'next-redux-wrapper';
import { setIdLookup } from 'lib/helpers';

export const adapter = createEntityAdapter({
  sortComparer: (a, b) => a.position - b.position,
});

export const { fetchAll: fetchLearningPaths, fetchById: fetchLearningPath } =
  createCrudReducers(
    'learningPaths',
    `${process.env.REST_API_URL}/v2/learning-paths`,
    {
      fetchById: {
        condition: ({ id }, { getState }) => {
          const state = getState().learningPaths;
          const record = state.entities[state.idLookup[id]];

          return !record?.populated;
        },
      },
    }
  );

const slice = createSlice({
  name: 'learningPaths',
  initialState: adapter.getInitialState({
    error: null,
    hydrated: false,
    status: 'idle',
    idLookup: {},
    page: { current: 0, next: null, prev: null, total: 0 },
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLearningPaths.fulfilled, (state, action) => {
        state.status = 'idle';
        adapter.upsertMany(state, action.payload.data);
        setIdLookup(state, action.payload.data || [], 'slug');
        state.page = { current: 1, next: null, prev: null, total: 1 };
      })
      .addCase(fetchLearningPaths.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchLearningPaths.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchLearningPath.fulfilled, (state, action) => {
        adapter.upsertOne(state, { ...action.payload.data, populated: true });
        setIdLookup(state, action.payload.data || [], 'slug');
      })
      .addCase(HYDRATE, (state, action) => {
        if (state.hydrated) return state;

        return {
          ...state,
          ...action.payload.learningPaths,
          hydrated: true,
        };
      });
  },
});

export const {
  selectAll: selectLearningPaths,
  selectById: selectLearningPath,
} = adapter.getSelectors((state) => state.learningPaths);

export const selectLearningPathBySlug = (state, slug) => {
  return state.learningPaths.entities[state.learningPaths.idLookup[slug]];
};

export const selectHasPremiumContent = (state, id) => {
  const path = selectLearningPath(state, id);

  return path?.steps?.some(
    (step) => state.challenges.entities[step.challenge]?.type === 'premium'
  );
};

export const selectIsLoading = (state) => state.plans.status === 'loading';

export default slice.reducer;
