import axios from 'axios';
import { createAsyncReducer, createCrudReducers } from '@app/crudReducers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectEntity } from 'lib/helpers';

export const { fetchAll: fetchLearners, fetchById: fetchLearner } =
  createCrudReducers('learners', `${process.env.REST_API_URL}/v2/learners`);

export const fetchLearnerComments = createAsyncReducer(
  'learners/comments',
  async ({ id, params, headers }) => {
    return (
      await axios.get(
        `${process.env.REST_API_URL}/v2/learners/${id}/comments`,
        {
          params,
          headers,
        }
      )
    ).data;
  },
  {
    condition: ({ id, params } = {}, { getState }) => {
      const state = getState().learners;
      const user = selectEntity(state, id.toLowerCase());

      if ((!params?.page || params?.page === 1) && user?.comments) {
        return false;
      }

      if (params?.page > 1 && !user.comments) {
        return false;
      }

      if (params?.page <= Math.ceil(user.comments / 24)) {
        return false;
      }

      return true;
    },
  }
);

export const fetchCount = createAsyncThunk('v2/learners/count', async () => {
  try {
    const response = await axios.get(`${process.env.REST_API_URL}/users/count`);

    return response.data;
  } catch {
    return null;
  }
});

export const fetchLearnerSolutions = createAsyncReducer(
  'learners/solutions',
  async ({ id, params, headers }) => {
    return (
      await axios.get(
        `${process.env.REST_API_URL}/v2/learners/${id}/solutions`,
        {
          params,
          headers,
        }
      )
    ).data;
  }
);
