import { createContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import useFetchInfinite from 'lib/hooks/useFetchInfinite';

import { selectCurrentUser } from 'features/auth/slice';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const currentUser = useSelector(selectCurrentUser);

  const {
    data: { data: notifications, meta: { page } = {} },
    isLoading,
    mutate,
    setPage,
  } = useFetchInfinite(currentUser ? '/api/v3/learner/notifications' : null);

  const markAsRead = async (pendingIds) => {
    const res = await fetch('/api/v3/learner/notifications/mark-as-read', {
      body: JSON.stringify({ ids: pendingIds }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    });

    if (!res.ok) {
      const body = await res.json();
      throw new Error(body.message);
    }

    mutate(
      async (data) => {
        return data.map(({ data, meta }) => {
          return { data: data.map((item) => ({ ...item, read: true })), meta };
        });
      },
      { revalidate: false }
    );
  };

  const pendingIds = useMemo(() => {
    if (!notifications) return [];

    return notifications.reduce((ids, notification) => {
      return notification.read ? ids : ids.concat(notification.id);
    }, []);
  }, [notifications]);

  return (
    <NotificationContext.Provider
      value={{
        isLoading,
        markAsRead,
        notifications,
        page,
        pendingIds,
        setPage,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
