import { selectCurrentUser } from 'features/auth/slice';
import { useSelector } from 'react-redux';

import DiscountBanner from './DiscountBanner';
import Logo from './Logo';
import Nav from './Nav';
import SignedInMenu from './SignedInMenu';
import SignedOutMenu from './SignedOutMenu';

export default function Header() {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <header>
      <DiscountBanner />

      <div className="relative px-5 z-40">
        <div className="container-full flex gap-6 items-center justify-between py-4">
          <Logo />

          <div className="flex gap-4 items-center">
            <Nav />

            {currentUser ? <SignedInMenu /> : <SignedOutMenu />}
          </div>
        </div>
      </div>
    </header>
  );
}
