import Footer from './Footer';
import Header from './Header';
import Nav from './Nav';
import Newsletter from './Newsletter';

export default function AppFooter() {
  return (
    <footer className="bg-neutralWhite flex flex-col gap-7 pt-7">
      <Header />

      <div className="px-5">
        <div className="container-full flex flex-wrap flex-col gap-7 tablet:flex-row tablet:justify-between">
          <Newsletter />

          <Nav />
        </div>
      </div>

      <Footer />
    </footer>
  );
}
