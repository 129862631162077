import NextLink from 'next/link';

import {
  COOKIE_POLICY_URL,
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
} from 'features/pages/slice';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="border-neutralLightGrey border-t px-5 py-4">
      <div className="container-full flex flex-col flex-wrap gap-x-6 gap-y-3 text-neutralDarkGrey text-sm mobile:flex-row mobile:justify-between">
        <span>© Frontend Mentor 2019 - {currentYear}</span>

        <ul className="flex flex-wrap gap-x-4 gap-y-3">
          <li>
            <a className="hover:underline" href={TERMS_AND_CONDITIONS_URL}>
              Terms
            </a>
          </li>

          <li>
            <a className="hover:underline" href={COOKIE_POLICY_URL}>
              Cookie Policy
            </a>
          </li>

          <li>
            <a className="hover:underline" href={PRIVACY_POLICY_URL}>
              Privacy Policy
            </a>
          </li>

          <li>
            <NextLink className="hover:underline" href="/license">
              License
            </NextLink>
          </li>
        </ul>
      </div>
    </div>
  );
}
