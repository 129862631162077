import { tv } from 'tailwind-variants';

const styles = tv({
  base: ['font-primary', 'bg-primaryBlue'],
  variants: {
    isTeamMember: {
      true: ['bg-primaryMagenta'],
    },
  },
});

import {
  Avatar,
  Bubble,
  Heading,
  Icon,
  LinkWithButtonStyles,
  Modal,
  useModal,
} from '@frontendmentorio/design-system-v2';

export default function Notch({ user, withModal }) {
  const modal = useModal();

  if (!user?.hasActiveSubscription) return null;

  if (!user.team?.avatar) {
    return (
      <Bubble
        className={styles({ isTeamMember: user.team })}
        title={
          user.team
            ? `${user.displayName} is associated with a team`
            : `${user.displayName} is a PRO member`
        }
      >
        {user.team ? 'T' : 'P'}
      </Bubble>
    );
  }

  if (!withModal) {
    return <Avatar className="w-full" image={user.team.avatar} size="md" />;
  }

  return (
    <>
      <button
        title={`${user.displayName} is associated with the ${user.team.name} team`}
        onClick={modal.open}
      >
        <Avatar className="w-full" image={user.team.avatar} size="md" />
      </button>

      <Modal modal={modal}>
        <Avatar image={user.team.avatar} size="xl" />

        <Modal.Heading>{user.team.name}</Modal.Heading>

        {user.team.description?.split('\n\n').map((paragraph, index) => (
          <p className="text-neutralDarkGrey" key={index}>
            {paragraph}
          </p>
        ))}

        <LinkWithButtonStyles
          className="text-sm px-3 py-2 flex gap-2"
          href={user.team.website}
          isGhost
          level="tertiary"
          target="_blank"
        >
          Learn more
          <Icon name="arrow-up-right-from-square" />
        </LinkWithButtonStyles>
      </Modal>
    </>
  );
}
