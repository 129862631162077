import { forwardRef } from 'react';
import { tv } from 'tailwind-variants';
import { useRouter } from 'next/router';

import { selectCurrentUser } from 'features/auth/slice';
import { selectIsTalentProfileComplete, signOut } from 'features/auth/slice';
import { useDispatch, useSelector } from 'react-redux';

import { flash, Icon, Menu, NavLink } from '@frontendmentorio/design-system-v2';

import Avatar from 'features/shared/Avatar';
import NotificationsNavLink from 'features/notifications/NavLink';
import MessagesNavLink from 'features/pages/Messaging/NavLink';
import TalentProfileNavLink from 'features/pages/TalentProfile/NavLink';

const iconStyles = tv({
  base: ['text-neutralBlack', 'transition-transform'],
  variants: {
    isActive: {
      true: ['rotate-180'],
    },
  },
});

const MenuButton = forwardRef(({ isActive, ...props }, ref) => {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <button {...props} aria-label="User menu" ref={ref}>
      <div className="gap-2 hidden items-center desktop:flex">
        <Avatar size="sm" user={currentUser} />

        <Icon
          className={iconStyles({ isActive })}
          name="chevron-down"
          style="solid"
        />
      </div>

      <Menu.Burger className="desktop:hidden" isActive={isActive} />
    </button>
  );
});

export default function SignedInMenu() {
  const dispatch = useDispatch();
  const router = useRouter();

  const canViewMessages = useSelector(selectIsTalentProfileComplete);
  const currentUser = useSelector(selectCurrentUser);

  const handleSignOut = async () => {
    await dispatch(signOut());

    flash.success('You have successfully signed out. See you again soon!');

    router.push('/');
  };

  return (
    <div className="flex gap-4 items-center">
      <ul className="flex gap-4 items-center">
        <li>
          <NotificationsNavLink />
        </li>

        <li>
          <TalentProfileNavLink currentUser={currentUser} />
        </li>

        {canViewMessages && (
          <li>
            <MessagesNavLink />
          </li>
        )}
      </ul>

      <Menu button={MenuButton}>
        <Menu.Nav ariaLabel="Primary" className="desktop:hidden">
          <Menu.Item>
            <NavLink href="/learning-paths" isUnderlineOnHover={false}>
              <Icon className="text-lg" name="arrow-trend-up" />
              Learning paths
            </NavLink>
          </Menu.Item>

          <Menu.Item>
            <NavLink href="/challenges" isUnderlineOnHover={false}>
              <Icon className="text-lg" name="laptop-mobile" />
              Challenges
            </NavLink>
          </Menu.Item>

          <Menu.Item>
            <NavLink href="/solutions" isUnderlineOnHover={false}>
              <Icon className="text-lg" name="code" />
              Solutions
            </NavLink>
          </Menu.Item>

          <Menu.Item>
            <NavLink href="/articles" isUnderlineOnHover={false}>
              <Icon className="text-lg" name="newspaper" />
              Articles
            </NavLink>
          </Menu.Item>

          {!currentUser.hasActiveSubscription && (
            <Menu.Item>
              <NavLink href="/pro" isUnderlineOnHover={false}>
                <Icon className="text-lg" name="unlock" />
                Unlock Pro
              </NavLink>
            </Menu.Item>
          )}
        </Menu.Nav>

        <Menu.Nav ariaLabel="User">
          <Menu.Item className="border-t desktop:hidden">
            <div className="flex gap-3 items-center">
              <Avatar size="xs" user={currentUser} />

              <span className={NavLink.styles({ level: 'primary' })}>
                My account
              </span>
            </div>
          </Menu.Item>

          <Menu.Item isSecondary>
            <NavLink href="/" isUnderlineOnHover={false}>
              <Icon className="text-lg" name="house" />
              Home
            </NavLink>
          </Menu.Item>

          <Menu.Item isSecondary>
            <NavLink
              href={`/profile/${currentUser.username}`}
              isUnderlineOnHover={false}
            >
              <Icon className="text-lg" name="user" />
              Profile
            </NavLink>
          </Menu.Item>

          <Menu.Item isSecondary>
            <NavLink href="/settings" isUnderlineOnHover={false}>
              <Icon className="text-lg" name="gear" />
              Settings
            </NavLink>
          </Menu.Item>

          <Menu.Item isSecondary>
            <NavLink
              className="text-primaryRed"
              isUnderlineOnHover={false}
              onClick={handleSignOut}
            >
              <Icon className="text-lg" name="arrow-right-from-bracket" /> Sign
              out
            </NavLink>
          </Menu.Item>
        </Menu.Nav>
      </Menu>
    </div>
  );
}
