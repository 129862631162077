import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { createCrudReducers } from '@app/crudReducers';

export const adapter = createEntityAdapter({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const { fetchAll: fetchTags } = createCrudReducers(
  'tags',
  `${process.env.REST_API_URL}/v2/tags`
);

const slice = createSlice({
  name: 'tags',
  initialState: adapter.getInitialState({
    error: null,
    hydrated: false,
    status: 'idle',
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTags.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.data);
      })
      .addCase(HYDRATE, (state, action) => {
        if (state.hydrated) return state;

        return {
          ...state,
          ...action.payload.tags,
          hydrated: true,
        };
      });
  },
});

export const { selectAll: selectTags, selectById: selectTag } =
  adapter.getSelectors((state) => state.tags);

export const selectTagsById = createSelector(
  [(state) => state.tags, (state, ids) => ids || []],
  (tags, ids) => ids.map((id) => tags.entities[id]).filter((tag) => !!tag)
);

export default slice.reducer;
