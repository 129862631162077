import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { createCrudReducers } from '@app/crudReducers';

export const adapter = createEntityAdapter();

export const { fetchAll: fetchBadges } = createCrudReducers(
  'badges',
  `${process.env.REST_API_URL}/v2/badges`
);

const slice = createSlice({
  name: 'badges',
  initialState: adapter.getInitialState({
    error: null,
    hydrated: false,
    status: 'idle',
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBadges.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.data || []);
      })
      .addCase(HYDRATE, (state, action) => {
        if (state.hydrated) return state;

        return {
          ...state,
          ...action.payload.badges,
          hydrated: true,
        };
      });
  },
});

export const {
  selectAll: selectBadges,
  selectById: selectBadge,
  selectIds: selectBadgeIds,
} = adapter.getSelectors((state) => state.badges);

export default slice.reducer;
