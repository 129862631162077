import { createContext, useEffect, useState, useRef } from 'react';
import { trackEvent } from 'fathom-client';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchCurrentUser,
  fetchInvoices,
  selectCurrentUser,
} from 'features/auth/slice';

import { usePolling } from '@frontendmentorio/design-system-v2';

export const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
  const MESSAGES = {
    loading:
      'Updating our records, this may take a few moments. Please do not refresh the browser',
    failed:
      'Connection lost. Please try refreshing your browser now. If there are any problems please contact us and we’ll sort it',
  };

  const isMounted = useRef();
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const [status, setStatus] = useState('idle');

  const handleFailure = () => {
    trackEvent('Subscription polling failed');
    setStatus('failed');
  };

  const { isPolling, startPolling, stopPolling } = usePolling(
    () => dispatch(fetchCurrentUser()),
    handleFailure,
    24
  );

  const pollForSubscription = () => {
    window.scrollTo(0, 0);
    setStatus('loading');
    startPolling();
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (!currentUser?.hasActiveSubscription) return;

    if (!isPolling) return;

    stopPolling();
    setStatus('success');
    dispatch(fetchInvoices());
  }, [
    currentUser?.hasActiveSubscription,
    currentUser?.subscription?.paymentMethod,
  ]);

  return (
    <SubscriptionContext.Provider
      value={{
        status,
        statusMessage: MESSAGES[status],
        pollForSubscription,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};
