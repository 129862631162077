import axios from 'axios';
import Router from 'next/router';
import { createAction } from '@reduxjs/toolkit';
import { createAsyncReducer } from '@app/crudReducers';
import { intersection } from 'lodash';
import { setAuthToken } from 'lib/helpers';

import { resetFeed } from 'features/feed/slice';
import { fetchConversations } from 'features/conversations/slice';

const signOut = createAction('auth/signOut');

export const deactivateSubscription = createAsyncReducer(
  'auth/deactivateSubscription',
  async ({ headers }) => {
    return (
      await axios.post(
        `${process.env.REST_API_URL}/subscription/deactivate`,
        null,
        { headers }
      )
    ).data;
  },
  {
    successMessage:
      'You have successfully cancelled your subscription. You can reactivate it at any time!',
    errorMessage:
      "Something went wrong trying to cancel your subscription. Please contact us and we'll sort it.",
  }
);

export const deleteCurrentUser = createAsyncReducer(
  'auth/delete',
  async ({ headers }) => {
    return (
      await axios.delete(
        `${process.env.REST_API_URL}/v2/auth/learner/profile`,
        {
          headers,
        }
      )
    ).data;
  },
  {
    onSuccess: (response, dispatch) => {
      dispatch(resetFeed());
      dispatch(signOut());
      Router.push('/');
    },
    successMessage:
      'Your account was successfully deleted. Sorry to see you go!',
  }
);

export const fetchCurrentUser = createAsyncReducer(
  'auth/fetchProfile',
  async ({ headers }) => {
    return (
      await axios.get(`${process.env.REST_API_URL}/v2/auth/learner/profile`, {
        headers,
      })
    ).data;
  }
);

export const fetchCurrentUserBookmarks = createAsyncReducer(
  'auth/fetchBookmarks',
  async ({ headers }) => {
    return (
      await axios.get(
        `${process.env.REST_API_URL}/v2/auth/learner/profile/bookmarks`,
        {
          headers,
        }
      )
    ).data;
  },
  {
    condition: (arg, { getState }) => {
      const currentUser = getState().auth.currentUser;

      if (currentUser.bookmarks) return false;
    },
  }
);

export const fetchCurrentUserChallenges = createAsyncReducer(
  'auth/fetchChallenges',
  async ({ headers }) => {
    return (
      await axios.get(
        `${process.env.REST_API_URL}/v2/auth/learner/profile/challenges`,
        {
          headers,
        }
      )
    ).data;
  },
  {
    condition: (arg, { getState }) => {
      const currentUser = getState().auth.currentUser;
      const challengeIds = getState().challenges.ids;

      const storedChallenges = intersection(
        currentUser?.unlockedHubs,
        challengeIds
      );

      return storedChallenges.length !== currentUser?.unlockedHubs?.length;
    },
  }
);

export const fetchCurrentUserFollowing = createAsyncReducer(
  'auth/fetchFollowing',
  async ({ headers }) => {
    return (
      await axios.get(
        `${process.env.REST_API_URL}/v2/auth/learner/profile/following`,
        {
          headers,
        }
      )
    ).data;
  },
  {
    condition: (arg, { getState }) => {
      const currentUser = getState().auth.currentUser;

      if (currentUser.following) return false;
    },
  }
);

export const fetchCurrentUserFollowers = createAsyncReducer(
  'auth/fetchFollowers',
  async ({ headers }) => {
    return (
      await axios.get(
        `${process.env.REST_API_URL}/v2/auth/learner/profile/followers`,
        {
          headers,
        }
      )
    ).data;
  },
  {
    condition: (arg, { getState }) => {
      const currentUser = getState().auth.currentUser;

      if (currentUser.followers) return false;
    },
  }
);

export const fetchCurrentUserPathProgresses = createAsyncReducer(
  'auth/fetchPathProgresses',
  async ({ headers }) => {
    return (
      await axios.get(
        `${process.env.REST_API_URL}/v2/auth/learner/path-progresses`,
        { headers }
      )
    ).data;
  },
  {
    condition: (arg, { getState }) => {
      const currentUser = getState().auth.currentUser;

      if (currentUser.pathProgresses) return false;
    },
  }
);

export const startLearningPath = createAsyncReducer(
  'auth/startLearningPath',
  async ({ headers, data }) => {
    return (
      await axios.post(
        `${process.env.REST_API_URL}/v2/auth/learner/path-progresses`,
        data,
        { headers }
      )
    ).data;
  }
);

export const fetchCurrentUserSolutions = createAsyncReducer(
  'auth/fetchSolutions',
  async ({ headers }) => {
    return (
      await axios.get(
        `${process.env.REST_API_URL}/v2/auth/learner/profile/solutions`,
        {
          headers,
        }
      )
    ).data;
  },
  {
    condition: (arg, { getState }) => {
      const state = getState().learners;
      const currentUser = state.entities[state.currentUserId];

      if (currentUser.solutions?.length >= currentUser.solutionCount) {
        return false;
      }
    },
  }
);

export const fetchInvoices = createAsyncReducer(
  'auth/fetchInvoices',
  async ({ headers }) => {
    return (
      await axios.get(`${process.env.REST_API_URL}/v2/invoices`, { headers })
    ).data;
  }
);

export const followChallenge = createAsyncReducer(
  'auth/followChallenge',
  async ({ id, headers }) => {
    return (
      await axios.post(
        `${process.env.REST_API_URL}/v2/auth/learner/profile/follow/challenges/${id}`,
        null,
        { headers }
      )
    ).data;
  },
  {
    onSuccess: async (response, dispatch) => {
      dispatch(resetFeed());
    },
  }
);

export const followTag = createAsyncReducer(
  'auth/followTag',
  async ({ id, headers }) => {
    return (
      await axios.post(
        `${process.env.REST_API_URL}/v2/auth/learner/profile/follow/tags/${id}`,
        null,
        { headers }
      )
    ).data;
  },
  {
    onSuccess: async (response, dispatch) => {
      dispatch(resetFeed());
    },
  }
);

export const followUser = createAsyncReducer(
  'auth/followUser',
  async ({ id, headers }) => {
    return (
      await axios.post(
        `${process.env.REST_API_URL}/v2/auth/learner/profile/follow/users/${id}`,
        null,
        { headers }
      )
    ).data;
  },
  {
    onSuccess: (response, dispatch) => {
      dispatch(resetFeed());
    },
  }
);

export const login = createAsyncReducer(
  'auth/login',
  async ({ code }) => {
    return (
      await axios.post(`${process.env.REST_API_URL}/v2/auth/learner/github`, {
        code,
      })
    ).data;
  },
  {
    errorMessage: false,
    onSuccess: (data, dispatch) => {
      setAuthToken(data.meta.token);

      dispatch(fetchConversations());
    },
  }
);

export const payInvoice = createAsyncReducer(
  'auth/payInvoice',
  async ({ headers, id }) => {
    return (
      await axios.post(
        `${process.env.REST_API_URL}/v2/invoices/${id}/pay`,
        null,
        { headers }
      )
    ).data;
  },
  {
    successMessage: 'Thanks for paying your outstanding invoice!',
  }
);

export const reactivateSubscription = createAsyncReducer(
  'auth/reactivateSubscription',
  async ({ headers }) => {
    return (
      await axios.delete(
        `${process.env.REST_API_URL}/subscription/deactivate`,
        { headers }
      )
    ).data;
  },
  {
    successMessage:
      'You have successfully reactivated your subscription. You can cancel it at any time!',
    errorMessage:
      "Something went wrong trying to reactivate your subscription. Please contact us and we'll sort it.",
  }
);

export const updateCurrentUser = createAsyncReducer(
  'auth/updateProfile',
  async ({ data, headers }) => {
    return (
      await axios.put(
        `${process.env.REST_API_URL}/v2/auth/learner/profile`,
        data,
        {
          headers,
        }
      )
    ).data;
  },
  {
    successMessage: 'Profile updated!',
  }
);
