export const COMMUNITY_GUIDELINES_URL =
  'https://medium.com/frontend-mentor/frontend-mentor-community-guidelines-44303cc38aa6';
export const COOKIE_POLICY_URL =
  'https://www.iubenda.com/privacy-policy/68783624/cookie-policy';
export const DISCORD_URL = 'https://discord.gg/UAfh3qzhYb';
export const DISCORD_FINISHED_PROJECTS_URL = 'https://discord.gg/qMaSw9Z3Kw';
export const FACEBOOK_URL = 'https://www.facebook.com/frontendmentor';
export const FEEDBACK_ARTICLE_URL =
  'https://www.frontendmentor.io/articles/how-to-effectively-give-and-receive-feedback-in-web-development-jrLC5dz7bm';
export const FEEDBACK_FORM_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLScDemjm8WphdO3tmG3B7kkdrzKa6h8-0CCHr4pI9bqtFdsOFQ/viewform';
export const GITHUB_OAUTH_URL = `https://github.com/login/oauth/authorize?client_id=${process.env.GITHUB_CLIENT_ID}&scope=user:email`;
export const LINKEDIN_URL = 'https://www.linkedin.com/company/frontend-mentor';
export const MIN_TALENT_MENTOR_SCORE = 150;
export const PRIVACY_POLICY_URL =
  'https://www.iubenda.com/privacy-policy/68783624';
export const SUBMISSION_GUIDE_URL =
  'https://medium.com/frontend-mentor/a-complete-guide-to-submitting-solutions-on-frontend-mentor-ac6384162248';
export const TALENT_PROFILE_GUIDE_URL =
  'https://medium.com/frontend-mentor/create-a-talent-profile-recruiters-will-love-e44d83de2112';
export const TEAMS_ARTICLE_URL =
  'https://www.frontendmentor.io/articles/how-to-get-manager-approval-for-frontend-mentor-for-teams';
export const X_URL = 'https://x.com/frontendmentor';
export const TERMS_AND_CONDITIONS_URL =
  'https://www.iubenda.com/terms-and-conditions/68783624';
export const TRUSTED_HOSTING_PROVIDERS =
  'https://medium.com/frontend-mentor/frontend-mentor-trusted-hosting-providers-bf000dfebe';
