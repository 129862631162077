import * as Sentry from '@sentry/nextjs';

import Layout from 'features/shared/Layout';

import { ErrorScreen } from '@frontendmentorio/design-system-v2';

export default function Error({ statusCode }) {
  return (
    <Layout
      description="Improve your front-end skills by building real projects. Solve real-world HTML, CSS and JavaScript challenges whilst working to professional designs."
      image="https://res.cloudinary.com/dz209s6jk/image/upload/v1585172856/Meta/meta-homepage.png"
      isIndexed={false}
      url="https://www.frontendmentor.io/"
    >
      <ErrorScreen
        code={statusCode}
        heading="Oops, this probably isn’t the page you expected to see..."
      >
        <div className="flex flex-col gap-4">
          <p>
            Sometimes this can be resolved by simply{' '}
            <a
              href="#"
              onClick={() => {
                window.location.reload(true);
              }}
            >
              reloading the page
            </a>
            .
          </p>

          <p>
            If the issue persists, please contact us at{' '}
            <a
              className="underline hover:no-underline"
              href="mailto:hi@frontendmentor.io"
            >
              hi@frontendmentor.io
            </a>
          </p>
        </div>
      </ErrorScreen>
    </Layout>
  );
}

Error.getInitialProps = async (contextData) => {
  await Sentry.captureUnderscoreErrorException(contextData);
};
