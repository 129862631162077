import NextLink from 'next/link';

import { Image } from '@frontendmentorio/design-system-v2';

export default function Logo() {
  return (
    <div className="text-[0rem]">
      <NextLink href="/" aria-label="Home page">
        <Image
          alt=""
          className="desktop:hidden"
          height={28}
          src="/static/images/logo-mobile.svg"
          width={31}
        />

        <Image
          alt=""
          className="hidden desktop:block"
          height={28}
          src="/static/images/logo-desktop.svg"
          width={196}
        />
      </NextLink>
    </div>
  );
}
