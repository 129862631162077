import NextLink from 'next/link';

import {
  DISCORD_URL,
  FACEBOOK_URL,
  LINKEDIN_URL,
  X_URL,
} from 'features/pages/slice';

import { Icon, Image } from '@frontendmentorio/design-system-v2';

export default function Header() {
  return (
    <div className="px-5">
      <div className="container-full flex flex-col gap-6 mobile:flex-row mobile:justify-between">
        <NextLink href="/">
          <Image
            alt="Frontend Mentor logo"
            height={28}
            src="/static/images/logo-desktop.svg"
            width={196}
          />
        </NextLink>

        <div className="flex items-center gap-5 text-primaryCyan text-xl">
          <a aria-label="Join our Discord server" href={DISCORD_URL}>
            <Icon name="discord" style="brands" />
          </a>

          <a aria-label="Visit our X page" href={X_URL}>
            <Icon name="x-twitter" style="brands" />
          </a>

          <a aria-label="Visit our LinkedIn page" href={LINKEDIN_URL}>
            <Icon name="linkedin" style="brands" />
          </a>

          <a aria-label="Visit our Facebook page" href={FACEBOOK_URL}>
            <Icon name="facebook" style="brands" />
          </a>
        </div>
      </div>
    </div>
  );
}
